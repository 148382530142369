<template>
  <div>
    <v-card-title>
      <navigation-breadcrumbs shift-matched />
    </v-card-title>
    <v-card-text>
      dash index
    </v-card-text>
  </div>
</template>

<script>
import NavigationBreadcrumbs from "@/components/NavigationBreadcrumbs";
export default {
  name: "DashboardIndex",
  components: { NavigationBreadcrumbs }
};
</script>
